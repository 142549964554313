'use client';

import type { Question } from '@features/api/client';
import QuestionAnswerInput from './QuestionAnswerInput';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';
import EditableText from '../Problem/EditableText';
import { Tooltip } from 'antd';
import { Fragment } from 'react';

type QuestionFillInTheBlankProps = {
	questions: Array<Question>;
	leadText?: string | null | undefined;
};

function QuestionFillInTheBlank({ questions, leadText }: QuestionFillInTheBlankProps) {
	return (
		<div className="prose !max-w-[75ch] [&_.question-text]:!inline [&_.question-text_p]:!inline">
			<EditableProblemRichtext
				text={leadText ? leadText + ' ' : ''}
				className="prose"
				fieldName="lead_text"
				modelParams={{ type: 'problem' }}
			/>
			{questions.map((question, questionIndex) => {
				return (
					<Fragment key={question.id}>
						<EditableProblemRichtext
							text={question.text}
							fieldName="text"
							modelParams={{ type: 'question', questionIndex: questionIndex }}
						/>
						{question.ordered_answers.map((answer, answerIndex) => (
							<QuestionAnswerInput
								answer={answer}
								inline
								answerIndex={answerIndex}
								questionIndex={questionIndex}
							/>
						))}

						<EditableText
							fieldName="points"
							modelParams={{ type: 'question', questionIndex }}
							text={question.points}
							className="relative ml-1 mr-2 inline rounded-[2px] bg-green-200 px-2 py-1 text-xs font-bold"
							data-tooltip="Broj bodova"
						/>

						<EditableProblemRichtext
							text={question.postanswer_text ? question.postanswer_text + ' ' : ''}
							fieldName="postanswer_text"
							modelParams={{ type: 'question', questionIndex: questionIndex }}
						/>
						{question.question_display_type === 'vertical' && (
							<>
								<br />
								<br />
							</>
						)}
					</Fragment>
				);
			})}
		</div>
	);
}

export default QuestionFillInTheBlank;
