'use client';

import createZustandContext from '@features/shared/providers/createZustandContext';
import { createStore } from 'zustand';
import type { EditProblemCandidateParams } from './utils/constructEditedProblemsImportCandidate';
import type { ProblemsImportCandidateProblem } from '@features/api/client';

type InitialState = {
	isProblemEditable?: boolean;
	isShowingComparisonData?: boolean;
	problemsImportCandidateId?: number;
	onTextEditChange?: (value: EditProblemCandidateParams) => void;
};

type ProblemEditableStoreState = InitialState & {
	setIsProblemEditable: (value: boolean) => void;
	setIsShowingComparisonData: (value: boolean) => void;
};
const ProblemEditableStore = createZustandContext<InitialState, ProblemEditableStoreState>((initialState) => {
	return createStore<ProblemEditableStoreState>()((set, get) => ({
		isProblemEditable: initialState.isProblemEditable,
		isShowingComparisonData: initialState.isShowingComparisonData,
		problemsImportCandidateId: initialState.problemsImportCandidateId,
		onTextEditChange: initialState.onTextEditChange,
		setIsProblemEditable: (value) =>
			set((state) => {
				return {
					isProblemEditable: value,
				};
			}),
		setIsShowingComparisonData: (value) =>
			set((state) => {
				return {
					isShowingComparisonData: value,
				};
			}),
	}));
});

export const useProblemEditableStore = ProblemEditableStore.useStore;
export const ProblemEditableStoreProvider = ProblemEditableStore.Provider;
