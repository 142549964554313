import type { Question } from '@features/api/client';
import ProblemContentBlock from '@features/shared/blocks/ProblemContentBlock';
import EditableProblemRichtext from '../Problem/EditableProblemRichtext';

type QuestionTextProps = {
	question: Question;
	questionIndex: number;
};

function QuestionText({ question, questionIndex }: QuestionTextProps) {
	return (
		<>
			<EditableProblemRichtext
				text={question.text}
				className="prose"
				fieldName="text"
				modelParams={{ type: 'question', questionIndex }}
			/>
			{question.blocks.map((block) => (
				<ProblemContentBlock block={block} key={block.id} />
			))}
		</>
	);
}

export default QuestionText;
