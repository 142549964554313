'use client';

import type { EnteredAnswerTextRequest, SelectedAnswerChoiceRequest } from '@features/api/client';
import createZustandContext from '@features/shared/providers/createZustandContext';
import { differenceWith, groupBy, isDeepEqual } from 'remeda';
import { createStore } from 'zustand';
import { QuestionsViewMode } from './QuestionsViewMode.types';

export type SelectedAnswerChoice = { questionId: number; answerId: number };
export type EnteredAnswerText = { questionId: number; answerText: string; answerId: number };

export type OnAnswerChangeProps = {
	enteredAnswerTexts: EnteredAnswerTextRequest[];
	selectedAnswerChoices: SelectedAnswerChoiceRequest[];
	questionId: number;
};
type InitialState = {
	mode: QuestionsViewMode;
	selectedAnswerChoices: SelectedAnswerChoice[];
	enteredAsnwerTexts: EnteredAnswerText[];
	areAnswersBeingChecked?: boolean;
	areAllAnswersBeingShown?: boolean;
	areCorrectAnswersBeingShown?: boolean;
	isCheckingAnswersInlineEnabled?: boolean;
	isChangingAnswersDisabled?: boolean;
	onAnswerChange?: (data: OnAnswerChangeProps) => void;
};

type QuestionGroupStoreState = InitialState & {
	setAreAnswersBeingChecked: (value: boolean) => void;
	setAreAllAnswersBeingShown: (value: boolean) => void;
	setSelectedAnswerChoices: (value: SelectedAnswerChoice[]) => void;
	addEnteredAnswerText: (value: EnteredAnswerText) => void;
	isQuizResultsMode: () => boolean;
	isExamMode: () => boolean;
	isPracticeMode: () => boolean;
};

const QuestionGroupStore = createZustandContext<InitialState, QuestionGroupStoreState>((initialState) => {
	return createStore<QuestionGroupStoreState>()((set, get) => ({
		mode: initialState.mode,
		selectedAnswerChoices: initialState.selectedAnswerChoices,
		enteredAsnwerTexts: initialState.enteredAsnwerTexts,
		areAllAnswersBeingShown: initialState.areAllAnswersBeingShown,
		areAnswersBeingChecked: initialState.areAnswersBeingChecked,
		areCorrectAnswersBeingShown: initialState.areCorrectAnswersBeingShown,
		isCheckingAnswersInlineEnabled: initialState.isCheckingAnswersInlineEnabled,
		isChangingAnswersDisabled: initialState.isChangingAnswersDisabled,
		onAnswerChange: initialState.onAnswerChange,
		addEnteredAnswerText: (value) =>
			set((state) => {
				if (state.isChangingAnswersDisabled) return state;

				const filteredEnteredAnswerTexts = state.enteredAsnwerTexts.filter(
					(text) => text.answerId !== value.answerId
				);
				const newEnteredAnswerTexts = [...filteredEnteredAnswerTexts, value];

				if (state.onAnswerChange) {
					state.onAnswerChange({
						enteredAnswerTexts: newEnteredAnswerTexts.map((answer) => ({
							answer_text: answer.answerText,
							answer_id: answer.answerId,
							question_id: answer.questionId,
						})),
						selectedAnswerChoices: [],
						questionId: value.questionId,
					});
				}

				return {
					enteredAsnwerTexts: newEnteredAnswerTexts,
				};
			}),
		setSelectedAnswerChoices: (value) =>
			set((state) => {
				if (state.isChangingAnswersDisabled) return state;

				const addedAnswerChoicesByQuestionId = groupBy(
					differenceWith(value, state.selectedAnswerChoices, isDeepEqual),
					(choice) => choice.questionId
				);

				Object.keys(addedAnswerChoicesByQuestionId).forEach((questionId) => {
					if (state.onAnswerChange) {
						state.onAnswerChange({
							enteredAnswerTexts: [],
							selectedAnswerChoices:
								addedAnswerChoicesByQuestionId[questionId]?.map((choice) => ({
									answer_id: choice.answerId,
									question_id: choice.questionId,
								})) || [],
							questionId: parseInt(questionId, 10),
						});
					}
				});

				return {
					selectedAnswerChoices: value,
				};
			}),
		setAreAnswersBeingChecked: (value) =>
			set((state) => {
				if (state.isChangingAnswersDisabled) return state;

				return {
					areAnswersBeingChecked: value,
				};
			}),
		setAreAllAnswersBeingShown: (value) =>
			set((state) => {
				if (state.isChangingAnswersDisabled) return state;

				return {
					areAllAnswersBeingShown: value,
				};
			}),
		isQuizResultsMode: () => get().mode === QuestionsViewMode.QUIZ_RESULTS,
		isExamMode: () => get().mode === QuestionsViewMode.EXAM,
		isPracticeMode: () => get().mode === QuestionsViewMode.PRACTICE,
	}));
});

// export default QuestionGroupStore;

export const useQuestionGroupStore = QuestionGroupStore.useStore;
export const QuestionGroupStoreProvider = QuestionGroupStore.Provider;
